import { deviceDetect, isMacOs } from "react-device-detect";

import { Platform } from "~/gql/main/types.generated";

export const getDeviceDetails = () => {
  const device = deviceDetect(window.navigator.userAgent);
  return {
    deviceModel: device?.isMobile
      ? device?.model || ""
      : device?.browserName || "",
    deviceVersion: device?.osVersion || "",
    platform: isMacOs ? Platform.IOS : Platform.ANDROID,
  };
};
